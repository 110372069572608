<style lang="scss" scoped>
	.contact-info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
	}

	hr {
		margin: 30px 0;
	}

	a,
	a:visited {
		color: $grey;

		&:hover {
			color: $orange;
		}
	}

	p {
		line-height: 1.2;
	}
</style>

<template>
	<section>
		<h1>Kontakt</h1>
		<p>
			Am liebsten ist mir der persönliche Kontakt per Telefon. Sie erreichen mich am besten gegen 13:00 Uhr oder ab 18:00 Uhr zu Hause. Unterwegs bin ich auf dem Handy erreichbar auch über WhatsApp oder per E-Mail.
		</p>

		<hr />
		<section class="contact-info">
			<div>
				<p>Klavierstimmer Andreas Schneider<br />St.-Martiner-Str. 23</p>
				<p>67487 Maikammer</p>
				<p>Mobil: 0179-1720 430</p>
				<p>Telefon: 06321-571 82</p>
				<p>E-Mail: <a href="mailto:info@piano-schneider.de">info@piano-schneider.de</a></p>
			</div>
			<img src="@/../public/img/kontakt/klavier_buch.jpg" alt="klavier_buch" />
		</section>
	</section>
</template>
